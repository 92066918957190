  .download-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  .download-container a{
      text-decoration: none;
      color: var(--opacity);
  }
  .download-button {
    background-color: #262a3b;
    border: none;
    color: --var(--opacity);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, border-radius 0.3s; 
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    transition: .5s;
  }
  
  .download-button .download {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .download-button:hover {
  color: var(--white);
  }
  