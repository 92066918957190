header .container {
  margin: 0 auto;
  display: flex;
  text-align: start;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  max-width: 600px;
}

header {
  padding: 50px 0px 20px 0px;
  margin: 0 auto;
}

header a{
  text-decoration: none;
}


header h1 {
  font-size: 1.5em;
  margin: 0;
}

header h1 a{ 
  color: var(--white);
  text-decoration: none;
}

header nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 5px;
}


header nav a {
  transition: .2s ease-in-out;
  padding: 3px 8px;
  border-radius: .375rem;
  text-decoration: none;
  color: var(--white);
}
header nav a:hover {
  background-color: var(--accent);
}
