

article {
  font-weight: 400;
  padding: 0px 20px 20px 20px;
  margin: 0 auto;
  max-width: 600px;
}
article h1 {
font-size: 1.5em;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
  margin: 30px 0 0px;
}

article h2 {
  font-size: 1.3em;
  font-weight: bold;
  margin: 20px 0 10px;
}

article p {
  margin: 0 0 10px;
  line-height: 1.3;
  font-size: .95em;
  color: var(--opacity);
}

article span {
  margin: 0 0 10px;
  line-height: 1.3;
  font-size: .95em;
}


.logos-container{
  display:flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  
}
.logos-container img{
  width: 35px;
  height: 35px;
  margin: 0 10px;
}

article .colorRed{
  color: var(--red);
  background-color: var(--accent);
  padding: 0px 5px 0px 5px;
  margin: 0 0 10px;
  border-radius: 3px;
  font-weight: 500;
  transition: .5s;

}
article .colorSky{
  
  color: var(--sky);
  background-color: var(--accent);
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
  font-weight: 500;
  transition: .5s;
}
article .colorOrange{
  color: var(--orange);
  background-color: var(--accent);
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
  font-weight: 500;
  transition: .5s;
}

article .colorGreen{
  color: var(--green);
  background-color: var(--accent);
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
  font-weight: 500;
  transition: .5s;
}

article .colorSky:hover{
  background-color: var(--sky);
  color: var(--accent);
  cursor: pointer;
}
article .colorRed:hover{
  background-color: var(--red);
  color: var(--accent);
  cursor: pointer;
}
article .colorGreen:hover{
  background-color: var(--green);
  color: var(--accent);
  cursor: pointer;
}

article .colorOrange:hover{
  background-color: var(--orange);
  color: var(--accent);
  cursor: pointer;
}
center h4{
  color: var(--opacity);
  font-weight: 500;
}
center a{
  padding: 0px 2px 1px 2px;
  color: var(--opacity);
  text-decoration: none;
  font-weight: 500;
  transition: .5s;
  border-bottom: 2px solid var(--opacity);
}
center a:hover{
  color: var(--white);
  border-bottom: 2px solid var(--white);
}

@media screen and (max-width: 500px) {

  article code {
    font-family: input-mono, monospace;
    font-weight: 500;
    right: 0;
    letter-spacing: 1px;
  }


}