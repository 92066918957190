@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-UltraLight.otf'); /* Add more formats as needed */
  font-weight: 200;
}
@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Light.otf'); /* Add more formats as needed */
  font-weight: 300;
}
@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Regular.otf'); /* Add more formats as needed */
  font-weight: 400;
}
@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-Medium.otf') ; /* Add more formats as needed */
  font-weight: 500;
}

@font-face {
  font-family: 'Geist';
  src: local('Geist'), url('./fonts/Geist-SemiBold.otf'); /* Add more formats as needed */
  font-weight: 600;
}


body {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent; /* For Android Chrome */
    -webkit-touch-callout: none; /* Disable long tap popup */
    -webkit-user-select: none; /* Disable selection on Android Chrome */
    -moz-user-select: none; /* Disable selection on Firefox */
    -ms-user-select: none; /* Disable selection on Internet Explorer/Edge */
    user-select: none; /* Disable selection on other browsers */
    touch-action: manipulation; /* Improve touch responsiveness */
    outline: none; /* Remove focus outline */

    font-family: 'Geist', Arial, Helvetica, sans-serif;
    --black: #111111;
    --white: #fdfdfd;
    --green: #22cc88;
    --blue: #000cf9;
    --sky: #01eeff;
    --red: #ff0055;
    --orange: #ff6200;
    --yellow: #ffcc00;
    --mustard: #ffaa00;
    --background: #13151c;
    --accent: #262a3b;
    --opacity: #cbceda;
    --opacity2: rgba(255, 255, 255, 0.429);
  
    margin: 0;
    padding: 0;

    background-color: var(--background);
    color: var(--white);
    

  }

::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }

  p{
    font-size: .95em;
    color: var(--opacity);
  }