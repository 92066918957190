.techs-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--accent);
    margin-top: 20px;  
  
  }
  
  .techs-container .techs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      columns: 3;
      gap: 15px 110px;
      margin-top: 10px;
  }
  .techs-container .techs img{
      width: 30px;
      height: 30px;
      transition: .5s;
  }
  
  .techs-container .icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .techs-container .icon-name {
    z-index: 5;
    position: absolute;
    top: 0px; /* Adjust as needed to position the name above the image */
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--background);
    color: var(--white);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 550;
    opacity: 0;
    transition: .5s ease;
    
  }
  
  .techs-container .icon-container:hover > .icon-name {
    top: 30px; /* Adjust as needed to position the name above the image */
    opacity: 1;
  }

  @media screen and (max-width: 500px) {

    .techs-container .techs {
      gap: 15px 40px;
  }

  }
  