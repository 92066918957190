

footer {
  bottom: 0;
  padding: 10px 0px 10px 0px;
  background-image: radial-gradient(
    rgba(255, 255, 255, 0) 1px,
    var(--accent) 1px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
}


.copyright{
  color: var(--opacity2); 
  font-size: 15px;
  font-weight: 300;
  max-width: 600px;
  padding: 0px 20px 40px 20px;
  margin: 0 auto;
}
