.card {
    background-color: var(--accent);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 24px;
    color: var(--opacity);
    transition: transform 0.5s ease;
    cursor: pointer;
    margin-top: 18px;
    
}


.card h2 {
    font-size: 1.3rem;
    margin-bottom: 12px;
}

.card p {
    font-size: .95em;
    line-height: 1.4;
}

.card .date{
    font-weight:300;
    color: var(--opacity2)
}

.card .footer {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card .footer a {
    color: var(--white);
    text-decoration: none;
    transition: color .15s ;
    position: relative; /* Ensure absolute positioning is relative to this element */
}

.card .footer a:hover {
    color: #0e87f9;
}
