.blog-container {
    font-weight: 400;
    padding: 0px 20px 20px 20px;
    margin: 0 auto;
    max-width: 600px;
}
.blog-container ul {
    list-style: square;
    margin: 0;
}
.blog-container ul li {
    margin: 0 0 10px;
    line-height: 1.3;
    font-size: .95em;
    color: var(--opacity);
}
.blog-container ul li:hover {
    filter: brightness(1.8);
}

.blog-container h1{
    font-size: 1.5em;
}

.blog-container a {
    color: var(--opacity);
    text-decoration: none;
    font-weight: 500;
    transition: .5s;
}
