.links-container {
    font-weight: 400;
    padding: 0px 20px 20px 20px;
    margin: 0 auto;
    max-width: 600px;
  }

.links-container h1{
      font-size: 1.5em;
  }
  
.links-container ul {
    padding: 0;
    margin: 0;
  }
.links-container ul li {
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
    font-size: .95em;
    color: var(--opacity);
  }

  .links-container ul li svg{
    transition: .2s ease-in-out;
    filter: brightness(0.8); /* Makes it darker (decrease value for more darkness) */
    height: 20px;
    width: 20px;
  }


  .links-container a {
      color: var(--opacity);
      text-decoration: none;
      font-weight: 500;
      transition: .5s;
      list-style: square  ;
  }  

  .links-container a:hover {
      filter: brightness(1.8); /* Makes it lighter (increase value for more lightness) */
  }


  .links-container ul li p{
    margin: 0;
  }