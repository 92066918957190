.container-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
    padding: 0px 20px 20px 20px;
    margin: 0 auto;
    text-align: center;
}
.container-404 h1 {
    font-size: 3em;
    margin: 0;
}

.container-404 p {
    font-size: 1.5em;
}